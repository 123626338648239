interface ICnLinks {
  name: string;
  url: string;
  rel?: HTMLAnchorElement["rel"];
  target?: HTMLAnchorElement["target"];
}

export const companyLinks: ICnLinks[] = [
  {
    name: "Developers",
    url: "https://developers.commoninja.com",
  },
  {
    name: "Supported Platforms",
    url: "/platforms",
  },
  {
    name: "Solutions",
    url: "/solutions",
  },
  {
    name: "Affiliate Program",
    url: "/affiliates",
  },
  {
    name: "Status Page",
    url: "https://commonninja.statuspage.io/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Blog",
    url: "/blog",
  },
];

export const support: ICnLinks[] = [
  {
    name: "Help Center",
    url: "https://help.commoninja.com/hc/en-us",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Community Forum",
    url: "https://community.commoninja.com/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Contact Us",
    url: "/contact-us",
  },
];

export const features: ICnLinks[] = [
  { url: "/features/design", name: "Design" },
  { url: "/features/payments", name: "Native Payments" },
  { url: "/features/analytics", name: "Analytics" },
  { url: "/features/api", name: "API" },
  { url: "/features/crm", name: "CRM" },
  { url: "/features/integrations", name: "Integrations" },
  { url: "/features/localization", name: "Localization" },
  { url: "/features/no-code-editor", name: "No-Code Editor" },
  { url: "/features/project-management", name: "Project Management" },
];

export const footerCompanyLinks: ICnLinks[] = [
  {
    name: "About Us",
    url: "/about-us",
  },
  {
    name: "Careers",
    url: "https://careers.commoninja.com",
  },
  {
    name: "Platform Features",
    url: "/features",
  },
  {
    name: "Status Page",
    url: "https://commonninja.statuspage.io/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Contact Us",
    url: "/contact-us",
  },
  {
    name: "Suggest a Widget+",
    url: "https://commoninja.site/suggest-a-widget",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Bracket Maker",
    url: "https://www.bracketsninja.com/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
];

export const footerResourcesLinks: ICnLinks[] = [
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Developers",
    url: "https://developers.commoninja.com",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Write for Us",
    url: "/write-for-us",
  },
  {
    name: "Alternatives",
    url: "/alternatives",
  },
  {
    name: "Solutions",
    url: "/solutions",
  },
  {
    name: "Coming Soon Widgets",
    url: "/coming-soon",
  },
  {
    name: "Built With Common Ninja",
    url: "/built-with",
  },
  {
    name: "Search Engine",
    url: "https://www.commoninja.com/discover",
    rel: "noopener noreferrer",
    target: "_blank",
  },
];

export const footerCommunityLinks: ICnLinks[] = [
  {
    name: "Help Center",
    url: "https://help.commoninja.com/hc/en-us",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Community Forum",
    url: "https://community.commoninja.com/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Feature Requests",
    url: "https://community.commoninja.com/",
    rel: "noopener noreferrer",
    target: "_blank",
  },
  {
    name: "Affiliate Program",
    url: "/affiliates",
  },
  {
    name: "Affiliate Resources",
    url: "/affiliates/resources",
  },
  {
    name: "Partners",
    url: "https://developers.commoninja.com/docs/white-label/overview",
    rel: "noopener noreferrer",
    target: "_blank",
  },
];